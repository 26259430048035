import { AppSettings } from '@configs/app.setting'
import { MENU_INDEXES } from '@models/menu-index'

export const menuList = [
  // {
  //   id: MENU_INDEXES.MENU_DASHBOARD_ID,
  //   codes: [],
  //   title: 'BUSINESS_SIDEBAR.DASHBOARD',
  //   alias: 'dashboard',
  //   routerLink: `/${AppSettings.FNB_ROUTE}`,
  //   icon: 'dashboard',
  //   iconSec: 'dashboard-sec',
  //   active: 'active-business',
  // },
  {
    id: MENU_INDEXES.MENU_DASHBOARD_MENU_ID,
    codes: [AppSettings.IDESHOP_DASHBOARD, AppSettings.ADMIN_IDESHOP_DASHBOARD],
    title: 'BUSINESS_SIDEBAR.DASHBOARD',
    alias: 'dashboard',
    routerLink: `/${AppSettings.FNB_ROUTE}`,
    icon: 'dashboard',
    iconSec: 'dashboard-sec',
    active: 'active-business',
  },
  // {
  //   id: MENU_INDEXES.MENU_BUSINESS_CREATE,
  //   codes: [AppSettings.IDESHOP_SA_BUSINESS, AppSettings.IDESHOP_ADM_BUSINESS],
  //   title: 'BUSINESS_SIDEBAR.CREATE_BUSINESS',
  //   alias: 'create-business',
  //   routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.BUSINESS_MENU}/${AppSettings.BUSINESS_CREATE_MENU}`,
  //   icon: 'create-business',
  //   iconSec: 'create-business-sec',
  //   active: 'active-business',
  //   isCreatable: false,
  // },
  // {
  //   id: MENU_INDEXES.MENU_BUSINESS_MANAGEMENT,
  //   codes: [AppSettings.IDESHOP_SA_BUSINESS, AppSettings.IDESHOP_ADM_BUSINESS],
  //   title: 'BUSINESS_SIDEBAR.BUSINESS_MANAGEMENT',
  //   alias: 'business-management',
  //   routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.BUSINESS_MENU}`,
  //   icon: 'business-management',
  //   iconSec: 'business-management-sec',
  //   active: 'active-business',
  //   isCreatable: false,
  //   isUpdatable: false,
  //   isDeletable: false,
  // },
  // {
  //   id: MENU_INDEXES.MENU_CHANNEL_MANAGEMENT,
  //   codes: [AppSettings.IDESHOP_SA_BUSINESS, AppSettings.IDESHOP_ADM_BUSINESS],
  //   title: 'BUSINESS_SIDEBAR.CHANNEL_MANAGEMENT',
  //   alias: 'channel-management',
  //   routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.SETTINGS_CHANNEL_MENU}`,
  //   icon: 'channel-management',
  //   iconSec: 'channel-management-sec',
  //   active: 'active-business',
  //   isCreatable: false,
  //   isUpdatable: false,
  //   isDeletable: false,
  // },
  {
    id: MENU_INDEXES.MENU_FNB_PRODUCT_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
    title: 'BUSINESS_SIDEBAR.ITEMS',
    alias: 'product',
    icon: 'products',
    iconSec: 'products',
    active: 'active-business',
    showDropdown: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.PRODUCT_ITEM_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_PRODUCT_ITEM_LIST_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.PRODUCT_ITEM_LIST',
        alias: 'item',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.MENU_MENU}/${AppSettings.PRODUCT_ITEM_MENU}`,
      },

      {
        id: MENU_INDEXES.MENU_FNB_PRODUCT_CATEGORY_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.PRODUCT_CATEGORY',
        alias: 'category',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.MENU_MENU}/${AppSettings.PRODUCT_CATEGORY_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_FNB_PRODUCT_MEDIA_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.PRODUCT_MEDIA',
        alias: 'media',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.MENU_MENU}/${AppSettings.PRODUCT_MEDIA_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_PRODUCT_CUSTOM_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.PRODUCT_CUSTOM_MENU',
        alias: 'custom-menu',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.MENU_MENU}/${AppSettings.PRODUCT_CUSTOM_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_FNB_INTEGRATION_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_INTEGRATION, AppSettings.IDESHOP_ADM_INTEGRATION],
    title: 'BUSINESS_SIDEBAR.INTEGRATION',
    alias: 'integration',
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INTEGRATION_MENU}`,
    icon: 'integration',
    iconSec: 'integration',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_FNB_INTEGRATION_PAYMENT_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_INTEGRATION, AppSettings.IDESHOP_ADM_INTEGRATION],
        name: 'BUSINESS_SIDEBAR.INTEGRATION_PAYMENT',
        alias: 'integration-payment',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INTEGRATION_MENU}/${AppSettings.INTEGRATION_PAYMENT_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_FNB_INTEGRATION_ACCOUNTING,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_INTEGRATION, AppSettings.IDESHOP_ADM_INTEGRATION],
        name: 'BUSINESS_SIDEBAR.INTEGRATION_ACCOUNTING',
        alias: 'accounting',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INTEGRATION_MENU}/${AppSettings.INTEGRATION_ACCOUNTING_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_FNB_INTEGRATION_DELIVERY_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_INTEGRATION, AppSettings.IDESHOP_ADM_INTEGRATION],
        name: 'BUSINESS_SIDEBAR.INTEGRATION_DELIVERY',
        alias: 'integration-delivery',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INTEGRATION_MENU}/${AppSettings.INTEGRATION_DELIVERY_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_FNB_INTEGRATION_IDECONNECT_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_INTEGRATION, AppSettings.IDESHOP_ADM_INTEGRATION],
        name: 'BUSINESS_SIDEBAR.INTEGRATION_IDECONNECT',
        alias: 'integration',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INTEGRATION_MENU}/${AppSettings.IDECONNECT_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_ORDER_CUSTOMER_MENU_ID,
    codes: [
      AppSettings.IDESHOP_SA_BUSINESS_ORDER,
      AppSettings.IDESHOP_SA_BUSINESS_CUSTOMER,
      AppSettings.IDESHOP_ADM_ORDER,
      AppSettings.IDESHOP_ADM_CUSTOMER,
    ],
    title: 'BUSINESS_SIDEBAR.ORDER_CUSTOMER',
    alias: 'orders',
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ORDER_MENU}/${AppSettings.ORDER_MENU_MENU}`,
    icon: 'orders',
    iconSec: 'orders',
    active: 'active-business',
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_ORDER_DETAIL_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ORDER, AppSettings.IDESHOP_ADM_ORDER],
        name: 'BUSINESS_SIDEBAR.ORDER',
        alias: 'order',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ORDER_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_ORDER_CUSTOMER_MENU_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_CUSTOMER, AppSettings.IDESHOP_ADM_CUSTOMER],
        name: 'BUSINESS_SIDEBAR.CUSTOMER',
        alias: 'customer',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.CUSTOMER_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_INGREDIENT_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
    title: 'BUSINESS_SIDEBAR.INGREDIENT',
    alias: 'ingredient',
    routerLink: '',
    icon: 'ingredient-icon',
    iconSec: 'ingredient-icon',
    active: 'active-business',
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_INGREDIENT_LIST_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
        name: 'BUSINESS_SIDEBAR.INGREDIENT_LIST',
        alias: 'ingredient-list',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INGREDIENT_MENU}/${AppSettings.INGREDIENT_LIST_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_INGREDIENT_CATEGORY_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.INGREDIENT_CATEGORY',
        alias: 'ingredient-category',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INGREDIENT_MENU}/${AppSettings.INGREDIENT_CATEGORY_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_INGREDIENT_UNIT_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.INGREDIENT_UNIT',
        alias: 'ingredient-unit-list',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INGREDIENT_MENU}/${AppSettings.INGREDIENT_UNIT_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_INGREDIENT_RECIPE_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT],
        name: 'BUSINESS_SIDEBAR.INGREDIENT_RECIPE',
        alias: 'ingredient-recipe',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INGREDIENT_MENU}/${AppSettings.INGREDIENT_RECIPE_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_INVENTORY_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
    title: 'BUSINESS_SIDEBAR.INVENTORY',
    alias: 'inventory',
    routerLink: '',
    icon: 'inventory-icon',
    iconSec: 'inventory-icon',
    active: 'active-business',
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_INVENTORY_STOCK_CARD_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
        name: 'BUSINESS_SIDEBAR.STOCK_CARD',
        alias: 'stock-card',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_STOCK_CARD_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_INVENTORY_MANAGE_STOCK_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
        name: 'BUSINESS_SIDEBAR.MANAGE_STOCK',
        alias: 'manage-stock',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_MANAGE_STOCK_MENU}`,
        showDropdown: false,
        children: [
          // {
          //   id: MENU_INDEXES.MENU_INVENTORY_TRANSFER_STOCK_ID,
          //   codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
          //   name: 'BUSINESS_SIDEBAR.TRANSFER_STOCK',
          //   alias: 'transfer-stock',
          //   routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_TRANSFER_STOCK_MENU}`,
          // },
          {
            id: MENU_INDEXES.MENU_INVENTORY_STOCK_IN_ID,
            codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
            name: 'BUSINESS_SIDEBAR.STOCK_IN',
            alias: 'stock-in',
            routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_STOCK_IN_MENU}`,
          },
          {
            id: MENU_INDEXES.MENU_INVENTORY_STOCK_OUT_ID,
            codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
            name: 'BUSINESS_SIDEBAR.STOCK_OUT',
            alias: 'stock-out',
            routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_STOCK_OUT_MENU}`,
          },
          // {
          //   id: MENU_INDEXES.MENU_INVENTORY_ADJUSTMENT_ID,
          //   codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
          //   name: 'BUSINESS_SIDEBAR.ADJUSTMENT',
          //   alias: 'adjustment',
          //   routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_ADJUSTMENT_MENU}`,
          // },
        ],
      },
      // {
      //   id: MENU_INDEXES.MENU_INVENTORY_PURCHASE_ID,
      //   codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
      //   name: 'BUSINESS_SIDEBAR.PURCHASE_ORDER',
      //   alias: 'purchase',
      //   isCreatable: false,
      //   isUpdatable: false,
      //   isDeletable: false,
      //   routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_PURCHASE_MENU}`,
      // },
      // {
      //   id: MENU_INDEXES.MENU_INVENTORY_SUPPLIER_ID,
      //   codes: [AppSettings.IDESHOP_SA_BUSINESS_PRODUCT, AppSettings.IDESHOP_ADM_PRODUCT], 
      //   name: 'BUSINESS_SIDEBAR.SUPPLIER',
      //   alias: 'supplier',
      //   isCreatable: false,
      //   isUpdatable: false,
      //   isDeletable: false,
      //   routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.INVENTORY_MENU}/${AppSettings.INVENTORY_SUPPLIER_MENU}`,
      // },
    ],
  },
  {
    id: MENU_INDEXES.MENU_FNB_ANALYTIC_ID,
    codes: [
      AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_PAYMENT_RECONCILIATION,
      AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_PRODUCT,
      AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_TRANSACTION,
      AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_SHIFT,
      AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_PROMOTION,
      AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_CATEGORY,
      AppSettings.IDESHOP_ADM_ANALYTIC_PAYMENT_RECONCILIATION,
      AppSettings.IDESHOP_ADM_ANALYTIC_PRODUCT,
      AppSettings.IDESHOP_ADM_ANALYTIC_TRANSACTION,
      AppSettings.IDESHOP_ADM_ANALYTIC_SHIFT,
      AppSettings.IDESHOP_ADM_ANALYTIC_PROMOTION,
      AppSettings.IDESHOP_ADM_ANALYTIC_CATEGORY,
    ],
    title: 'BUSINESS_SIDEBAR.ANALYTIC',
    alias: 'analytics',
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}`,
    icon: 'analytic',
    iconSec: 'analytic',
    active: 'active-business',
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_ANALYTIC_ORDER_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_TRANSACTION, AppSettings.IDESHOP_ADM_ANALYTIC_TRANSACTION],
        name: 'ANALYTIC.MENU_ORDER',
        alias: 'analytic-order',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}/${AppSettings.ANALYTIC_ORDER_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_ANALYTIC_ITEM_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_PRODUCT, AppSettings.IDESHOP_ADM_ANALYTIC_PRODUCT],
        name: 'Items',
        alias: 'analytic-item',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}/${AppSettings.ANALYTIC_ITEM_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_ANALYTIC_CATEGORY_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_CATEGORY, AppSettings.IDESHOP_ADM_ANALYTIC_CATEGORY],
        name: 'Category',
        alias: 'analytic-category',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}/${AppSettings.ANALYTIC_CATEGORY_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_ANALYTIC_SHIFT_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_SHIFT, AppSettings.IDESHOP_ADM_ANALYTIC_SHIFT],
        name: 'Shift',
        alias: 'analytic-shift',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}/${AppSettings.ANALYTIC_SHIFT_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_ANALYTIC_PAYMENT_RECONCILIATION_ID,
        codes: [
          AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_PAYMENT_RECONCILIATION,
          AppSettings.IDESHOP_ADM_ANALYTIC_PAYMENT_RECONCILIATION,
        ],
        name: 'BUSINESS_SIDEBAR.ANALYTIC_PAYMENT_RECONCILIATION',
        alias: 'analytic-payment-reconciliation-date',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}/${AppSettings.ANALYTIC_PAYMENT_RECONCILIATION_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_ANALYTIC_PROMOTIONS_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ANALYTIC_PROMOTION, AppSettings.IDESHOP_ADM_ANALYTIC_PROMOTION],
        name: 'BUSINESS_SIDEBAR.ANALYTIC_PROMOTIONS',
        alias: 'analytic-promotion',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ANALYTIC_MENU}/${AppSettings.ANALYTIC_PROMOTIONS_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_EMPLOYEE_ID,
    codes: [AppSettings.IDESHOP_SA_EMPLOYEE, AppSettings.IDESHOP_ADM_EMPLOYEE],
    title: 'BUSINESS_SIDEBAR.EMPLOYEE',
    alias: 'employee',
    icon: 'employee',
    iconSec: 'employee-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.EMPLOYEE_MENU}`,
  },
  {
    id: MENU_INDEXES.MENU_PROMO_COUPON_MENU_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_PROMOTION_COUPON, AppSettings.IDESHOP_ADM_PROMOTION_COUPON],
    title: 'BUSINESS_SIDEBAR.PROMO',
    alias: 'promotions',
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.PROMO_MENU}`,
    icon: 'promotion',
    iconSec: 'promotion',
    active: 'active-business',
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_PROMO_DETAIL_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PROMOTION_COUPON, AppSettings.IDESHOP_ADM_PROMOTION_COUPON],
        name: 'BUSINESS_SIDEBAR.SUB_PROMO',
        alias: 'promo',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.PROMO_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_COUPON_DETAIL_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_PROMOTION_COUPON, AppSettings.IDESHOP_ADM_PROMOTION_COUPON],
        name: 'BUSINESS_SIDEBAR.SUB_COUPON',
        alias: 'coupon',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.PROMO_MENU}/${AppSettings.PROMO_COUPON_MENU}`,
      },
    ],
  },
  // {
  //   title: 'BUSINESS_SIDEBAR.CONTENT',
  //   alias: 'content',
  //   icon: 'contents',
  //   iconSec: 'contents-sec',
  //   active: 'active-business',
  //   routerLink: '/pages/contents',
  //   subtitle: [
  //     {
  //       name: 'BUSINESS_SIDEBAR.ANNOUNCEMENT',
  //       alias: 'content',
  //       routerLink: '/pages/contents/announcement',
  //     },
  //     // {
  //     //   name: WORDS.BUSINESS_SIDEBAR.BLOG,
  //     //   alias: 'blog',
  //     //   routerLink: '/pages/products/blog',
  //     // },
  //   ],
  //   showDropdown: false,
  // },
  {
    id: MENU_INDEXES.MENU_APPEREANCE_FNB_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
    title: 'BUSINESS_SIDEBAR.IDEFOOD',
    alias: 'idefood',
    icon: 'idefood',
    iconSec: 'idefood-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.APPEREANCE_FNB_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_APPEREANCE_THEME_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
        name: 'BUSINESS_SIDEBAR.APPEREANCE',
        alias: 'idefood-appearance',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.APPEREANCE_FNB_MENU}/${AppSettings.APPEREANCE_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_APPEARANCE_SETTING_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
        name: 'BUSINESS_SIDEBAR.APPEARANCE_SETTING',
        alias: 'idefood-setting',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.APPEREANCE_FNB_MENU}/${AppSettings.APPEREANCE_SETTING_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_IDEQUEUE_ID,
    codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
    title: 'BUSINESS_SIDEBAR.IDEQUEUE',
    alias: 'idequeue',
    icon: 'idequeue',
    iconSec: 'idequeue-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.IDEQUEUE_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_IDEQUEUE_APPEARANCE_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
        name: 'BUSINESS_SIDEBAR.IDEQUEUE_APPEARANCE',
        alias: 'idequeue-appearance',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.IDEQUEUE_MENU}/${AppSettings.IDEQUEUE_APPEARANCE_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_IDEQUEUE_SETTINGS_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
        name: 'BUSINESS_SIDEBAR.IDEQUEUE_SETTING',
        alias: 'idequeue-setting',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.IDEQUEUE_MENU}/${AppSettings.IDEQUEUE_SETTINGS_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_IDEQUEUE_QR_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_APPEREANCE, AppSettings.IDESHOP_ADM_APPEREANCE],
        name: 'BUSINESS_SIDEBAR.IDEQUEUE_QR',
        alias: 'idequeue-setting',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.IDEQUEUE_MENU}/${AppSettings.IDEQUEUE_QR_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_CUSTOMER_DISPLAY_ID,
    codes: [AppSettings.IDESHOP_SA_CUSTOMER_DISPLAY, AppSettings.IDESHOP_ADM_CUSTOMER_DISPLAY],
    title: 'BUSINESS_SIDEBAR.CUSTOMER_DISPLAY',
    alias: 'customer display',
    icon: 'customer-display',
    iconSec: 'customer-display',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.CUSTOMER_DISPLAY_MENU}`,
  },
  {
    id: MENU_INDEXES.MENU_QUEUE_NUMBER_DISPLAY_ID,
    codes: [AppSettings.IDESHOP_SA_QUEUE_NUMBER_DISPLAY, AppSettings.IDESHOP_ADM_QUEUE_NUMBER_DISPLAY],
    title: 'BUSINESS_SIDEBAR.QUEUE_NUMBER_DISPLAY',
    alias: 'Queue Number Display',
    icon: 'queue-number-display',
    iconSec: 'queue-number-display',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.QUEUE_NUMBER_DISPLAY_MENU}`,
  },
  {
    id: MENU_INDEXES.MENU_KDS_ID,
    codes: [AppSettings.IDESHOP_SA_KDS, AppSettings.IDESHOP_ADM_KDS],
    title: 'BUSINESS_SIDEBAR.KDS_EDS',
    alias: 'kds',
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KDS_MENU}`,
    icon: 'icon-kds',
    iconSec: 'icon-kds',
    active: 'active-business',
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_KDS_GENERAL_ID,
        codes: [AppSettings.IDESHOP_SA_KDS, AppSettings.IDESHOP_ADM_KDS],
        name: 'BUSINESS_SIDEBAR.GENERAL',
        alias: 'general',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KDS_MENU}/${AppSettings.KDS_GENERAL_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_KDS_DISPLAY_ID,
        codes: [AppSettings.IDESHOP_SA_KDS, AppSettings.IDESHOP_ADM_KDS],
        name: 'BUSINESS_SIDEBAR.DISPLAY',
        alias: 'display',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KDS_MENU}/${AppSettings.KDS_DISPLAY_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_KDS_TICKET_ID,
        codes: [AppSettings.IDESHOP_SA_KDS, AppSettings.IDESHOP_ADM_KDS],
        name: 'BUSINESS_SIDEBAR.TICKET',
        alias: 'ticket',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KDS_MENU}/${AppSettings.KDS_TICKET_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_BRANCH_ID,
    codes: [
      AppSettings.IDESHOP_SA_BUSINESS_BRANCH,
      AppSettings.IDESHOP_SA_BUSINESS_BRANCH_STORE,
      AppSettings.IDESHOP_SA_BUSINESS_BRANCH_POS,
      AppSettings.IDESHOP_ADM_BRANCH_STORE,
      AppSettings.IDESHOP_ADM_BRANCH_POS,
    ],
    title: 'BUSINESS_SIDEBAR.BRANCH',
    alias: 'branch',
    icon: 'branch',
    iconSec: 'branch-sec',
    active: 'active-business',
    showDropdown: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.BRANCH_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_BRANCH_STORE_ID,
        codes: [
          AppSettings.IDESHOP_SA_BUSINESS_BRANCH,
          AppSettings.IDESHOP_SA_BUSINESS_BRANCH_STORE,
          AppSettings.IDESHOP_ADM_BRANCH_STORE,
        ],
        name: 'BUSINESS_SIDEBAR.STORE',
        alias: 'store',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.BRANCH_MENU}/${AppSettings.BRANCH_STORE_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_BRANCH_POS_DEVICE_ID,
        codes: [
          AppSettings.IDESHOP_SA_BUSINESS_BRANCH,
          AppSettings.IDESHOP_SA_BUSINESS_BRANCH_POS,
          AppSettings.IDESHOP_ADM_BRANCH_POS,
        ],
        name: 'BUSINESS_SIDEBAR.POS_DEVICE',
        alias: 'pos-device',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.BRANCH_MENU}/${AppSettings.BRANCH_POS_DEVICE_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_TABLE_MANAGEMENT_ID,
    codes: [AppSettings.IDESHOP_SA_TABLE_MANAGEMENT, AppSettings.IDESHOP_ADM_TABLE_MANAGEMENT],
    title: 'BUSINESS_SIDEBAR.TABLE_MANAGEMENT',
    alias: 'table',
    icon: 'table',
    iconSec: 'table-sec',
    active: 'active-business',
    showDropdown: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.TABLE_MANAGEMENT_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_TABLE_QR_GENERATOR_ID,
        codes: [AppSettings.IDESHOP_SA_TABLE_MANAGEMENT, AppSettings.IDESHOP_ADM_TABLE_MANAGEMENT],
        name: 'BUSINESS_SIDEBAR.TABLE_QR_GENERATOR',
        alias: 'table-qr',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.TABLE_MANAGEMENT_MENU}/${AppSettings.TABLE_QR_GENERATOR_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_TABLE_SETUP_ID,
        codes: [AppSettings.IDESHOP_SA_TABLE_MANAGEMENT, AppSettings.IDESHOP_ADM_TABLE_MANAGEMENT],
        name: 'BUSINESS_SIDEBAR.TABLE_SETUP',
        alias: 'table-management',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.TABLE_MANAGEMENT_MENU}/${AppSettings.TABLE_TABLE_SETUP}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_MERCHANT_LOG,
    codes: [AppSettings.IDESHOP_SA_EMPLOYEE, AppSettings.IDESHOP_ADM_EMPLOYEE],
    // codes: [AppSettings.IDESHOP_SA_MERCHANT_LOG, AppSettings.IDESHOP_ADM_MERCHANT_LOG],
    title: 'BUSINESS_SIDEBAR.MERCHANT_LOG',
    alias: 'merchant log',
    icon: 'log-report',
    iconSec: 'log-report-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.MERCHANT_LOG}`,
  },
  {
    id: MENU_INDEXES.MENU_FNB_SETTING_ID,
    codes: [
      AppSettings.IDESHOP_SA_BUSINESS_SETTING,
      AppSettings.IDESHOP_SA_BUSINESS_SETTING_DELIVERY,
      AppSettings.IDESHOP_SA_BUSINESS_SETTING_OPERATIONAL,
      AppSettings.IDESHOP_SA_BUSINESS_SETTING_TAX,
      AppSettings.IDESHOP_SA_BUSINESS_SETTING_CONTACT,
      AppSettings.IDESHOP_SA_SETTING_ROUNDING,
      AppSettings.IDESHOP_SA_SETTING_TIP,
      AppSettings.IDESHOP_ADM_SETTING,
      AppSettings.IDESHOP_ADM_SETTING_DELIVERY,
      AppSettings.IDESHOP_ADM_SETTING_OPERATIONAL,
      AppSettings.IDESHOP_ADM_SETTING_CONTACT,
      AppSettings.IDESHOP_ADM_SETTING_TAX,
      AppSettings.IDESHOP_ADM_SETTING_ROUNDING,
      AppSettings.IDESHOP_ADM_SETTING_TIP,
    ],
    title: 'BUSINESS_SIDEBAR.SETTINGS',
    alias: 'settings',
    icon: 'settings',
    iconSec: 'settings',
    active: 'active-business',
    showDropdown: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_SETTING_BUSINESS_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_SETTING, AppSettings.IDESHOP_ADM_SETTING],
        name: 'BUSINESS_SIDEBAR.BUSINESS',
        alias: 'setting-business',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_BUSINESS_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_ORDER_STATUS_ID,
        codes: [
          AppSettings.IDESHOP_SA_SETTING_ORDER_STATUS_PARENT,
          AppSettings.IDESHOP_ADM_SETTING_ORDER_STATUS_PARENT,
        ],
        name: 'BUSINESS_SIDEBAR.SETTING_ORDER_STATUS',
        alias: 'setting-order-status',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_ORDER_STATUS_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_OPERATION_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_SETTING_OPERATIONAL, AppSettings.IDESHOP_ADM_SETTING_OPERATIONAL],
        name: 'BUSINESS_SIDEBAR.SETTING_OPERATION',
        alias: 'setting-operation-hour',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_OPERATIONAL_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_SCHEDULED_ORDER,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_SETTING_OPERATIONAL, AppSettings.IDESHOP_ADM_SETTING_OPERATIONAL],
        name: 'BUSINESS_SIDEBAR.SETTING_SCHEDULED_ORDER',
        alias: 'setting-scheduled-order',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_SCHEDULED_ORDER}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_RECEIPT_KITCHEN_TEMPLATE_ID,
        codes: [AppSettings.IDESHOP_SA_SETTING_RECEIPT, AppSettings.IDESHOP_ADM_SETTING_RECEIPT],
        name: 'BUSINESS_SIDEBAR.SETTING_RECEIPT',
        alias: 'setting-receipt-kitchen-template',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_RECEIPT_KITCHEN_TEMPLATE}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_ADDRESS_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_SETTING_DELIVERY, AppSettings.IDESHOP_ADM_SETTING_DELIVERY],
        name: 'BUSINESS_SIDEBAR.ADDRESS',
        alias: 'setting-address',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_ADDRESS_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_CONTACT_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_SETTING_CONTACT, AppSettings.IDESHOP_ADM_SETTING_CONTACT],
        name: 'BUSINESS_SIDEBAR.SETTING_CONTACT',
        alias: 'setting-contact',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_CONTACT_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_TAX_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_SETTING_TAX, AppSettings.IDESHOP_ADM_SETTING_TAX],
        name: 'BUSINESS_SIDEBAR.SETTING_TAX',
        alias: 'setting-tax',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_TAX_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTING_ROUNDING_ID,
        codes: [AppSettings.IDESHOP_SA_SETTING_ROUNDING, AppSettings.IDESHOP_ADM_SETTING_ROUNDING],
        name: 'BUSINESS_SIDEBAR.SETTING_ROUNDING',
        alias: 'setting-rounding',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_ROUNDING_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_SETTINGS_TIPPING_ID,
        codes: [AppSettings.IDESHOP_SA_SETTING_TIP, AppSettings.IDESHOP_ADM_SETTING_TIP],
        name: 'BUSINESS_SIDEBAR.TIPPING_FEATURE',
        alias: 'setting-tipping',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_TIPPING_MENU}`,
      },
      // {
      //   id: MENU_INDEXES.MENU_SETTING_DOMAIN_ID,
      //   codes: [AppSettings.IDESHOP_SETTING],
      //   name: 'BUSINESS_SIDEBAR.DOMAIN',
      //   alias: 'settings',
      //   isCreatable: false,
      //   isUpdatable: false,
      //   isDeletable: false,
      //   routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.SETTINGS_MENU}/${AppSettings.SETTINGS_DOMAIN_MENU}`,
      // },
    ],
  },
  {
    id: MENU_INDEXES.MENU_FNB_USER_PERMISSION_ID,
    codes: [
      AppSettings.IDESHOP_SA_BUSINESS_ROLE,
      AppSettings.IDESHOP_SA_BUSINESS_USER,
      AppSettings.IDESHOP_ADM_ROLE,
      AppSettings.IDESHOP_ADM_USER,
    ],
    title: 'PERMISSION.USER_PERMISSION_TITLE',
    alias: 'role',
    icon: 'role',
    iconSec: 'role-sec',
    active: 'active-business',
    showDropdown: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ROLE_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_FNB_USER_DETAIL_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_USER, AppSettings.IDESHOP_ADM_USER],
        name: 'PERMISSION.USER_TITLE',
        alias: 'user',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ROLE_MENU}/${AppSettings.ROLE_USER_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_FNB_USER_ROLE_ID,
        codes: [AppSettings.IDESHOP_SA_BUSINESS_ROLE, AppSettings.IDESHOP_ADM_ROLE],
        name: 'PERMISSION.ROLE_TITLE',
        alias: 'role',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.ROLE_MENU}/${AppSettings.ROLE_MANAGEMENT_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_IDEKIOSK_ID,
    codes: [AppSettings.IDESHOP_ADM_KIOSK, AppSettings.IDESHOP_SA_KIOSK],
    title: 'BUSINESS_SIDEBAR.IDEKIOSK',
    alias: 'idekiosk',
    icon: 'idekiosk',
    iconSec: 'idekiosk-sec',
    active: 'active-business',
    showDropdown: false,
    routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KIOSK_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_IDEKIOSK_APPEARANCE_ID,
        codes: [AppSettings.IDESHOP_ADM_KIOSK, AppSettings.IDESHOP_SA_KIOSK],
        name: 'BUSINESS_SIDEBAR.IDEKIOSK_APPEREANCE',
        alias: 'appearance',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KIOSK_MENU}/${AppSettings.KIOSK_APPEARANCE_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_IDEKIOSK_SETTINGS_ID,
        codes: [AppSettings.IDESHOP_ADM_KIOSK, AppSettings.IDESHOP_SA_KIOSK],
        name: 'BUSINESS_SIDEBAR.IDEKIOSK_SETTING',
        alias: 'settings',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.FNB_ROUTE}/${AppSettings.KIOSK_MENU}/${AppSettings.KIOSK_SETTINGS_MENU}`,
      },
    ],
  },
]
