import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { DOCUMENT, formatDate } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { SidebarService } from '@layouts/pages/sidebar/sidebar.service'
import { COOKIE_NAME_LIST } from '@core/models/auth.model'
import {
  BrowserType,
  DateRangePicker,
  ErrorCustom,
  GraphData,
  GraphTooltip,
  ModalConfig,
  ModalResponse,
  PhoneCode,
  Toast,
  ScreenMode,
  UserInfo,
  TourStepModel,
} from '@core/models/common.model'
import { TranslateService } from '@ngx-translate/core'
import { CommonRepositoryService } from '@repository/common-repository/common-repository.service'
import { AdminBusinessService } from '@services/admin-business/admin-business.service'
import { AuthService } from '@services/auth/auth.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { ViewService } from '@services/view/view.service'
import { getTimezoneOffset } from '@core/utils/app-stuff'
import { getAdminPrivilage, setLastLogin } from '@core/utils/auth-stuff'
import { getBusinessType } from '@core/utils/business'
import { TempPhoneList } from '@core/utils/phone-code-list'
import { getBusinessName } from '@core/utils/user-stuff'
import saveAs from 'file-saver'
import { BehaviorSubject, Subject } from 'rxjs'
import {
  DAY_IN_TIMESTAMP,
  PeriodCode,
  PeriodType,
  TimeFormat,
  WEEK_IN_TIMESTAMP,
} from 'src/app/feature/analytic/models/analytic.model'
import { Router } from '@angular/router'
import { MENU_INDEXES } from '@models/menu-index'
import * as introJs from 'intro.js'
import { IntroJs } from 'intro.js/src/intro'

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private _sidebarService: SidebarService,
    private _authService: AuthService,
    private _businessService: AdminBusinessService,
    private _translateService: TranslateService,
    private _commonRepository: CommonRepositoryService,
    protected customCookieService: CustomCookieService,
    private _bpo: BreakpointObserver,
    private _router: Router
  ) {}

  WsDisabled = true
  greetings: string[] = []
  public stompClient: any
  public dom = Inject(DOCUMENT)

  toast: Subject<Toast> = new Subject<Toast>()
  // topbarDropdown: Subject<boolean> = new Subject()
  modal: Subject<ModalConfig> = new Subject<ModalConfig>()
  modalResponse: Subject<ModalResponse> = new Subject<ModalResponse>()
  modalForceConfirm: Subject<boolean> = new Subject<boolean>()
  modalForceCancel: Subject<boolean> = new Subject<boolean>()
  activeItemSidebar: Subject<string> = new Subject<string>()
  errorPage: Subject<boolean> = new Subject<boolean>()
  errorCustom: Subject<ErrorCustom> = new Subject<ErrorCustom>()
  informSubmitOnboarding: Subject<boolean> = new Subject<boolean>()
  informOnloadLp: Subject<boolean> = new Subject<boolean>()
  informOnloadEcommerce: Subject<boolean> = new Subject<boolean>()
  userInfo$ = new BehaviorSubject<UserInfo | null>(null) as BehaviorSubject<UserInfo | null>
  isShowVideoPlayer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  isFinishTour$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  isMerchantSetupCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  notificationMsg!: string
  introJS = introJs.default()
  tourTimeout!: ReturnType<typeof setTimeout>
  currencyUsingComa: string[] = ['SGD', 'PHP', 'USD', 'KHR', "AUD", "TWD"]
  currencyWithCent: string[] = ['SGD', 'PHP', 'USD', "AUD", "TWD"]

  // Initial Scale
  setInitialScale(isStatic: boolean) {
    const dynamicScale = 'width=device-width, initial-scale=1'
    const staticScale = 'width=device-width, initial-scale=0'
    let setScale = dynamicScale

    if (isStatic) {
      setScale = staticScale
    }

    this.dom.querySelector('meta[name=viewport]')?.setAttribute('content', setScale)
  }

  getDashboardUrl(): string {
    const monitoredBusiness = this._businessService.getMonitoredBusiness()

    // If user has no access to analyti item, redirect to dashboard staff !temporary!
    let isAccessDashboard: boolean = false
    let currMenuList: any = localStorage.getItem(AppSettings.CURRENT_MENU_LIST)

    try {
      currMenuList = JSON.parse(currMenuList)

      if (!currMenuList || !currMenuList?.length) {
        this.clearLocalData()
        return ``
      }
      
    } catch(e) {
      this.clearLocalData()
      return ``
    }

    currMenuList?.map((menu) => {
      if (getAdminPrivilage()) {
        isAccessDashboard = true
        return
      }

      if (menu.id === MENU_INDEXES.MENU_FNB_ANALYTIC_ID) {
        menu.subtitle.map((sub) => {
          if (sub?.id === MENU_INDEXES.MENU_ANALYTIC_ITEM_ID) {
            isAccessDashboard = true
          }
        })
      }
    })

    if (!isAccessDashboard) {
      return `${AppSettings.FNB_ROUTE}/${AppSettings.DASHBOARD_MENU_TYPE_STAFF}`
    }

    if (getAdminPrivilage() && (!monitoredBusiness || monitoredBusiness === null)) {
      return `${AppSettings.ADMIN_ROUTE}/${AppSettings.DASHBOARD_MENU}`
    } else if (getBusinessType() == AppSettings.BUSINESS_TYPE_MENU) {
      return `${AppSettings.FNB_ROUTE}/${AppSettings.DASHBOARD_MENU}`
    } else if (getBusinessType() == AppSettings.BUSINESS_TYPE_RETAIL) {
      return `${AppSettings.RETAIL_ROUTE}/${AppSettings.DASHBOARD_MENU}`
    } else {
      const lastLogin = getBusinessName()?.toString() || ''
      setLastLogin(lastLogin)
      this.clearLocalData()

      return ``
    }
  }

  // Web Socker: Ws
  setConnected(connected: boolean) {
    this.WsDisabled = !connected

    if (connected) {
      this.greetings = []
    }
  }

  monthToString(month: number) {
    switch (month) {
      case 1:
        return 'Jan'
      case 2:
        return 'Feb'
      case 3:
        return 'Mar'
      case 4:
        return 'Apr'
      case 5:
        return 'May'
      case 6:
        return 'Jun'
      case 7:
        return 'Jul'
      case 8:
        return 'Aug'
      case 9:
        return 'Sep'
      case 10:
        return 'Oct'
      case 11:
        return 'Nov'
      case 12:
        return 'Dec'
      default:
        return 0
    }
  }

  /**
   * Line Graph
   */

  setupGraph(
    date: DateRangePicker,
    chartData: GraphData,
    tooltipData: GraphTooltip[],
    pastData: boolean = false
  ): { chart: GraphData; tooltip: GraphTooltip[] } {
    const { start, end } = date
    let dateDiff: number = Math.floor((end - start) / (1000 * 60 * 60 * 24))
    let chart: GraphData
    let tooltip: GraphTooltip[]
    let newLabels: string[] = []
    let dayListSorted = [] as string[]

    chartData.labels = []
    newLabels = []
    dayListSorted = []

    if (dateDiff === 6) {
      let dayList: string[] = [
        this._translateService.instant('DATE_RANGE_PICKER.DAY_SUNDAY'),
        this._translateService.instant('DATE_RANGE_PICKER.DAY_MONDAY'),
        this._translateService.instant('DATE_RANGE_PICKER.DAY_TUESDAY'),
        this._translateService.instant('DATE_RANGE_PICKER.DAY_WEDNESDAY'),
        this._translateService.instant('DATE_RANGE_PICKER.DAY_THURSDAY'),
        this._translateService.instant('DATE_RANGE_PICKER.DAY_FRIDAY'),
        this._translateService.instant('DATE_RANGE_PICKER.DAY_SATURDAY'),
      ]

      for (
        let i = new Date(formatDate(start, 'short', 'en-US', `GMT${getTimezoneOffset()}`)).getDay();
        i < dayList.length;
        i++
      ) {
        dayListSorted.push(dayList[i])
      }

      for (let i = 0; i <= new Date(formatDate(end, 'short', 'en-US', `GMT${getTimezoneOffset()}`)).getDay(); i++) {
        dayListSorted.push(dayList[i])
      }

      chart = {
        ...chartData,
        labels: dayListSorted,
      }

      tooltip = this.generateTooltipLabel(dateDiff, dayListSorted, false, tooltipData, pastData)
    } else if (dateDiff === 13) {
      for (let index = 1; index <= 14; index++) {
        newLabels.push(index.toString())
      }
      chart = { ...chartData, labels: newLabels }
      tooltip = this.generateTooltipLabel(dateDiff, newLabels, false, tooltipData, pastData)
    } else {
      for (let index = 1; index <= dateDiff + 1; index++) {
        newLabels.push(index.toString())
      }

      chart = { ...chartData, labels: newLabels }
      tooltip = this.generateTooltipLabel(dateDiff, newLabels, true, tooltipData, pastData, start)
    }

    return { chart, tooltip }
  }

  generateTooltipLabel(
    dateDiff: number,
    labelList: string[],
    isCustom: boolean,
    tooltipData: GraphTooltip[],
    pastData: boolean,
    start: number = new Date().getTime()
  ): GraphTooltip[] {
    tooltipData.map((tooltipItem: GraphTooltip) => {
      let latestDate: number = 0

      switch (tooltipItem.label) {
        case 'current':
          latestDate = isCustom
            ? new Date(start).setDate(new Date(start).getDate() - 1)
            : new Date().setDate(new Date().getDate() - (dateDiff + 1))
          break

        case 'past':
          latestDate = isCustom
            ? new Date(start).setDate(new Date(start).getDate() - (dateDiff + 2))
            : new Date().setDate(new Date().getDate() - (dateDiff + 1) * 2)
          break

        default:
          latestDate = isCustom
            ? new Date(start).setDate(new Date(start).getDate() - 1)
            : new Date().setDate(new Date().getDate() - (dateDiff + 1))
          break
      }

      labelList.map((day: string) => {
        latestDate = new Date(latestDate).setDate(new Date(latestDate).getDate() + 1)
        tooltipItem.value[day] = formatDate(latestDate, 'dd/MM/yyyy', 'en-us', `GMT${getTimezoneOffset()}`)
      })
    })

    return tooltipData
  }

  /**
   * Service for get and set phone code
   */
  setPhoneCode(phoneCode: PhoneCode[]): void {
    if (phoneCode.length > 0) {
      phoneCode.map((phone) => {
        phone.phoneLabel = `+${phone.phoneInitial}`
      })
    }
    localStorage.setItem(AppSettings.phoneCode, JSON.stringify(phoneCode))
  }

  getPhoneCode(): PhoneCode[] {
    let result: PhoneCode[] = []
    let rawPhoneCode: string | undefined = localStorage.getItem(AppSettings.phoneCode) || undefined

    if (typeof rawPhoneCode != 'undefined') {
      result = JSON.parse(rawPhoneCode).map((phoneCode: PhoneCode) => {
        phoneCode.name = `${phoneCode.name} (${phoneCode.phoneLabel})`
        return phoneCode
      })
    } else {
      result = []
    }

    return result
  }

  getAnalyticFilterWeek(PeriodType: PeriodType): TimeFormat {
    let timeConverted: any = {
      start: 0,
      end: 0,
    }

    const currentDate = new Date().getTime()

    if (PeriodType == PeriodCode.PERIOD_WEEK) {
      timeConverted.start = currentDate + DAY_IN_TIMESTAMP - WEEK_IN_TIMESTAMP
      timeConverted.end = currentDate
    }

    if (PeriodType == PeriodCode.PERIOD_2_WEEK) {
      timeConverted.start = currentDate - (WEEK_IN_TIMESTAMP * 2 - DAY_IN_TIMESTAMP)
      timeConverted.end = currentDate
    }

    return timeConverted
  }

  /**
   * Service for download
   */

  setDownloadedProduct(file: string) {
    localStorage.setItem('download file', file)
  }

  getDownloadedProduct() {
    return localStorage.getItem('download file')
  }

  downloadNotification() {
    const toastConfig: Toast = new Toast()
    toastConfig.headless = true
    toastConfig.msg = this.notificationMsg
    toastConfig.closeLess = true
    this.toast.next(toastConfig)
  }

  private _download(token: string, fileName: string) {
    if (token.length == 0) return
    this._commonRepository.downloadProduct(token).subscribe((response) => {
      const blob = new Blob([response], { type: 'application/octet-stream' })
      let fr = new FileReader()

      fr.readAsDataURL(blob)
      fr.onloadend = (e) => {
        let base64File = fr.result?.toString().substr(fr.result?.toString().indexOf(',') + 1)
        this.setDownloadedProduct(base64File as string)
        let getLocalFile = this.getDownloadedProduct()
        const byteCharacters = atob(getLocalFile as string)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        const newBlob = new Blob([byteArray], { type: 'application/octet-stream' })
        saveAs(newBlob, fileName)
        this.notificationMsg = 'PORTAL_PRODUCTS.PRODUCT_EXPORT_SUCCEED'
        this.downloadNotification()
      }
    })
  }

  downloadChecker(token: string, fileName: string) {
    if (token.length == 0) return
    this._commonRepository.checkDownloadRequest(token).subscribe((response) => {
      this.notificationMsg = `${this._translateService.instant('PORTAL_PRODUCTS.PRODUCT_EXPORT_ON_PROGRESS')} ${
        response.data.progress
      }%`
      this.downloadNotification()
      if (response.data.status === 90) {
        this._download(token, fileName)
        return
      } else if (response.data.status === 99) {
        // This condition is temporary, waiting for backend
        const modalConfig: ModalConfig = new ModalConfig()
        modalConfig.contentLess = true
        modalConfig.buttonLess = true
        modalConfig.title = 'DOWNLOAD_EXPORT.ERROR_FAILED'

        this.modal.next(modalConfig)
      } else {
        this.downloadChecker(token, fileName)
      }

      // const timer = setTimeout(() => {
      //   downloadChecker()
      //   clearTimeout(timer)
      // }, 1000)
    })
  }

  /** ===================================================== */

  setCustomPeriodic(date: DateRangePicker): boolean {
    let result!: boolean

    const dateDiff =
      Math.floor(
        (Date.UTC(new Date(date.end).getFullYear(), new Date(date.end).getMonth(), new Date(date.end).getDate()) -
          Date.UTC(
            new Date(date.start).getFullYear(),
            new Date(date.start).getMonth(),
            new Date(date.start).getDate()
          )) /
          (1000 * 60 * 60 * 24)
      ) + 1

    if (dateDiff >= 6) {
      result = false
    } else {
      result = true
    }

    return result
  }

  /**
   * Set Default date today 12AM and end date tomorrow 11:59:59:999 PM
   */
  setDefaultTodayTomorrow(): DateRangePicker {
    let today = new Date()
    let tomorrow = new Date()

    today.setHours(0, 0, 0, 0)
    tomorrow.setDate(new Date().getDate() + 1)
    tomorrow.setHours(23, 59, 59, 999)

    return {
      start: this.getDateWithTimezone(today.getTime()),
      end: this.getDateWithTimezone(tomorrow.getTime()),
    }
  }

  /**
   * Set Ranging date by day
   * Start Date will set the hour to 00:00AM
   * End date will set the hour to 12:59PM
   */
  setDateRangeByDay(range: number = 0): DateRangePicker {
    let start = new Date()
    let end = new Date()

    if (range < 0) {
      start.setDate(start.getDate() + range)
    } else {
      end.setDate(end.getDate() + range)
    }
    start.setHours(0, 0, 0, 0)
    end.setHours(23, 59, 0, 0)

    return {
      start: this.getDateWithTimezone(start.getTime()),
      end: this.getDateWithTimezone(end.getTime()),
    }
  }

  /**
   * Set start date at 12AM and end date at 11:59PM with Timezone
   * @param {DateRangePicker} dateRange
   * @returns {DateRangePicker}
   */
  getDateRangeWithTimezone(dateRange: DateRangePicker): DateRangePicker {
    let { start, end } = dateRange
    start = new Date(start).setHours(0, 0, 0, 0)
    end = new Date(end).setHours(23, 59, 59, 999)

    return {
      start: this.getDateWithTimezone(start),
      end: this.getDateWithTimezone(end),
    }
  }

  /**
   * Set date with Timezone
   * @param {number} val
   * @returns {number} Date with timezone
   */
  getDateWithTimezone(val: number): number {
    let date = new Date(formatDate(val, 'medium', 'en-US'))
    let tzDate = new Date(formatDate(val, 'medium', 'en-US', `GMT${getTimezoneOffset()}`))
    const offset = date.getTime() - tzDate.getTime()

    return date.setTime(date.getTime() + offset)
  }

  /**
   * Get browser type
   * @returns {string}
   */
  getBrowserType(): BrowserType {
    const agent = window.navigator.userAgent.toLowerCase() as any
    let type = '' as BrowserType

    switch (true) {
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        type = 'chrome'
        break

      case agent.indexOf('firefox') > -1:
        type = 'firefox'
        break

      case agent.indexOf('safari') > -1:
        type = 'safari'
        break

      default:
        break
    }

    return type
  }

  setScreenView(): ScreenMode {
    const styleToCheck = '(min-width: 800px)'
    let screenView!: ScreenMode

    this._bpo.observe([styleToCheck]).subscribe((result) => {
      if (result.matches) {
        screenView = 'desktop'
      } else {
        screenView = 'mobile'
      }
    })

    return screenView
  }

  setScreenMode(result: any): ScreenMode {
    let screen!: ScreenMode

    const displayNameMap = new Map([
      [Breakpoints.XSmall, 'mobile'],
      [Breakpoints.Small, 'tablet'],
      [Breakpoints.Medium, 'tablet'],
      [Breakpoints.Large, 'desktop'],
      [Breakpoints.XLarge, 'desktop'],
    ])

    for (const query of Object.keys(result.breakpoints)) {
      if (result.breakpoints[query]) {
        screen = displayNameMap.get(query) as ScreenMode
      }
    }

    return screen
  }

  showSidebarHandler(): boolean {
    const SESSION_SIDEBAR: string = 'pi-ui-sidebar-no-expand'
    const element = document.getElementById('accordionSidebar') as HTMLElement
    let sidebar = sessionStorage.getItem(SESSION_SIDEBAR)
    let show: boolean = false

    if (!element) return show

    // Set SidebarNoExpand to sessionStorage
    if (element && sidebar) {
      sessionStorage.setItem(SESSION_SIDEBAR, 'sidebarNoExpand')
    } else if (element && element.classList.toggle('sidebarExpand')) {
      sessionStorage.setItem(SESSION_SIDEBAR, 'sidebarExpand')
      show = true
    } else {
      sessionStorage.setItem(SESSION_SIDEBAR, 'sidebarExpand')
      show = true
    }

    if (this.setScreenView() === 'desktop') {
    } else {
      this.sidebarMobileHandler(show)
    }

    return show
  }

  sidebarMobileHandler(isShow: boolean) {
    const sidebarDom: HTMLElement | null = document.getElementById('sidebarMobileToggle')
    const backDropDom: HTMLElement | null = document.getElementById('sidebarMobileBackdropToggle')
    if (!sidebarDom || !backDropDom) return
    if (isShow) {
      sidebarDom.classList.toggle('initial')
      backDropDom.classList.toggle('initial')
    } else {
      sidebarDom.classList.toggle('d-none')
      backDropDom.classList.toggle('d-none')
    }
  }

  setupTour(steps: TourStepModel[], menuType: string): void {
    if (localStorage.getItem('isFinishTour') === 'true') return

    const tourSteps: TourStepModel[] = []

    steps.forEach((step: TourStepModel) => {
      tourSteps.push({
        element: step.element,
        title: this._translateService.instant(step.title),
        intro: this._translateService.instant(step.intro),
      })
    })

    this.introJS.setOptions({
      steps: tourSteps,
      showProgress: false,
      disableInteraction: false,
      exitOnOverlayClick: false,
      showBullets: true,
      prevLabel: this._translateService.instant('BUTTON.BTN_PREVIOUS'),
      doneLabel: this._translateService.instant('BUTTON.BTN_FINISH'),
    })

    this.tourTimeout = setTimeout(() => {
      this.introJS.start()
      this.introJS
        .onchange(() => {
          if (this.introJS._currentStep !== 0) {
            ;(document.getElementsByClassName('introjs-prevbutton')[0] as HTMLElement).style.display = 'block'
          } else {
            ;(document.getElementsByClassName('introjs-prevbutton')[0] as HTMLElement).style.display = 'none'
          }
        })
        .oncomplete(() => this.setFinishTour(menuType))
        .onskip(() => this.setFinishTour(menuType))

      if (this.introJS._currentStep === 0) {
        ;(document.getElementsByClassName('introjs-prevbutton')[0] as HTMLElement).style.display = 'none'
      } else {
        ;(document.getElementsByClassName('introjs-prevbutton')[0] as HTMLElement).style.display = 'block'
      }
    }, 500)
  }

  closeTour(): void {
    this.introJS.exit(false)
    clearTimeout(this.tourTimeout)
  }

  setFinishTour(menuType: string): void {
    this.isFinishTour$.next(true)
    localStorage.setItem('isFinishTour', 'true')

    this._commonRepository
      .setTourStatus({
        menuType,
      })
      .subscribe()
  }

  getNpsModalVisibility(): boolean {
    let result!: boolean
    if (localStorage.getItem('isMerchantSetupCompleted') === null) {
      result = false
    } else {
      result = localStorage.getItem('isMerchantSetupCompleted') === 'true' ? true : false
    }
    return result
  }

  setNpsModalVisibility(value: boolean): void {
    localStorage.setItem('isMerchantSetupCompleted', JSON.stringify(value))
    this.isMerchantSetupCompleted$.next(value)
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object))
  }

  validateInputNumericOnly(input: KeyboardEvent): boolean {
    const allowedKeys: string[] = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ]

    return allowedKeys.includes(input.key)
  }

  validateInputCurrency(e: KeyboardEvent, currency: string): boolean {
    if (this.currencyWithCent.includes(currency)) {
      return true
    } else {
      return this.validateInputNumericOnly(e)
    }
  }

  generateValueFromCurrency(value: string, currency): number {
    if (this.currencyUsingComa.includes(currency)) {
      return Number(value.replaceAll(',', ''))
    } else {
      return Number(value.replaceAll('.', ''))
    }
  }

  isCurrencyWithCent(currency: string): boolean {
    return this.currencyWithCent.includes(currency)
  }

  onBlurAllInputComponent() {
    let inputDom = document.getElementsByTagName('input')

    if (inputDom?.length === 0) return

    for (let idx = 0; idx <= inputDom.length - 1; idx++) {
      inputDom[idx].blur()
    }
  }

  clearLocalData(): void {
    localStorage.clear()
    COOKIE_NAME_LIST.map((cookieName) => this.customCookieService.removeItem(cookieName))
    this._router.navigateByUrl('/login')
  }
}
